import React, { Component } from "react"
import SEO from "../components/seo"
import "../styles/style.css"
import Layout from "../components/layout"

class ThankYou extends Component {
    render () {

        return (
            <>
            <SEO title="Accessibility Policy" description="Professional web accessibility services" />
            <Layout>
                <div class="container">
                    <center><h2>Accessibility Policy</h2></center><br />
                     
                    <strong>Accessibility Support</strong><br />
                    <p>Do you need assistance accessing something on our website?</p>

                    <p>Email info@betteraccesspro.com and we will reply as soon as possible.</p>

                    <p>Call us at 804-592-6381 for phone assistance between the hours of 6am-6pm daily.</p>

                    <strong>We're Committed</strong>
                    <p>Better Access is committed to accessibility and ensuring that all users of this site, including those with disabilities and their companies, have an equal opportunity to participate in and benefit from all information and online offerings of Better Access.</p>

                    <p>Should you have any difficulty accessing this website in any manner, please do not hesitate to email us at info@betteraccesspro.com.</p>

                    <strong>Actions Taken</strong><br />
                    <span>Some of the measures taken to make betteraccesspro.com accessible include:</span>

                    <ul>
                      <li>adding alt text to all images</li>
                      <li>ensuring a minimum 4.5:1 color ratio for all text</li>
                      <li>using descriptive text links and headers</li>
                      <li>maintaining a consistent and predictable layout and organization</li>
                      <li>eliminating all automatic content (e.g. pop-ups, auto-playing media)</li>
                      <li>including a skip to main content link on each page</li>
                      <li>using descriptive anchor text for links</li>
                      <li>incorporating a focus indicator</li>
                      <li>ensuring all components are reachable by keyboard only</li>
                      <li>form input fields have clear labels</li>
                      <li>beyond our existing remediation measures, we continually review our website to ensure we adhere to best practices in web accessibility.</li>
                    </ul>

                    <p>Further, we voraciously research web accessibility best practices and methods to improve our knowledge and stay up to date on the latest news and information.</p>

                    <strong>Legal Requirements</strong>
                    <p>Through our genuine and good faith commitment and devotion to accessibility, we believe we not only meet but exceed the digital accessibility requirements imposed by Title I, II, & III of the Americans with Disabilities Act (ADA) and Section 504 & 508 of the Rehabilitation Act.</p>

                    <strong>Feedback</strong>
                    <p>Do you have feedback on betteraccesspro.com’s accessibility? Where can we improve? What are our strong points? Your feedback is welcome and greatly appreciated as we strive to improve.</p>

  
                </div>
            </Layout>
            </>
        )
    }
}

export default ThankYou